<template>
  <CardForm :titulo="titleCard">
    <div class="col-12">
      <div class="row">
        <float-label
          placeholder="MES"
          label="MES:"
          clase="col-md-3 col-6"
          id="mes"
          classInput="form-control"
          :select="true"
          v-model="month"
        >
          <template slot="options">
            <option :value="null" selected>Seleccione mes</option>
            <option v-for="m in meses" :value="m" :key="m">
              {{ m }}
            </option>
          </template>
        </float-label>
        <float-label
          placeholder="AÑO"
          label="AÑO:"
          clase="col-md-3 col-6"
          id="año"
          classInput="form-control"
          :select="true"
          v-model="year"
        >
          <template slot="options">
            <option :value="null" selected>Seleccione año</option>
            <option v-for="n in años" :key="n" :value="n">
              {{ n }}
            </option>
          </template>
        </float-label>
        <div class="col-12 col-md-3">
          <button class="btn btn-primary btn-block btn-wide" @click="getLiquidacion">
            Buscar
          </button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <ul class="list-group list-group-flush mb-3" v-if="liquidaciones">
        <li class="list-group-item hoverable" v-for="liquidacion in liquidaciones" :key="liquidacion.id" @click="opciones(liquidacion)">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper row">
              <div class="widget-content-left col-12">
                <div class="widget-subheading opacity-7">
                    <span class="text-primary">{{liquidacion.institucion.razon_social}}</span>
                </div>
                <h5 class="widget-heading opacity-9 mb-0">
                  <span class="opacity-9">
                    {{liquidacion.periodo}} 
                  </span> - <span class="opacity-9"> {{liquidacion.tipo_liquidacion}}</span>
                </h5>
                <div class="widget-subheading opacity-10 row">
                  <span class="col-sm-auto col-12">
                    <b class="text-secondary">
                    Fecha pago:
                      <span class="text-secondary">{{liquidacion.fecha_pago}}</span>
                    </b></span
                  >
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="!liquidaciones || liquidaciones.length == 0">
        <h4 class="text-center"> No se han encontrado liquidaciones </h4>
      </div>
    </div>
  </CardForm>
</template>
<script>
import FloatLabel from "@/components/layouts/FloatLabel";
import CardForm from "@/components/layouts/CardForm";
import DataList from "@/components/layouts/DataList";
import Axios from 'axios';
import Swal from  'sweetalert2'
export default {
  name: "ListarLiquidaciones",
  components: {
    FloatLabel,
    CardForm,
    DataList,
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  data() {
    return {
      month: "",
      year: "",
      liquidaciones:null
    };
  },
  computed: {
    años: function () {
      let array = [];
      for (let index = 2020; index <= 2035; index++) {
        array.push(index);
      }
      return array;
    },
    meses: function () {
      return [
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE",
      ];
    },
  },
  mounted() {
    this.año = this.$moment(this.fecha).format("YYYY");
    this.mes = this.$moment(this.fecha).format("MMMM").toUpperCase();
    this.year = this.$moment(this.fecha).format("YYYY");
    this.month = this.$moment(this.fecha).format("MMMM").toUpperCase();
    this.getLiquidaciones();
  },
  methods: {
    opciones(liquidacion) {
      this.$router.push({
        name: "VerLiquidacion",
        params: { id: liquidacion.id },
      });
    },
    getLiquidacion() {
      let params = {
        periodo: this.month + " " + this.year,
      };
      Axios.get("liquidaciones/periodo", { params: { ...params } })
        .then((r) => {
          this.liquidaciones = r.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getLiquidaciones() {
      let params = {
        periodo: this.month + " " + this.year,
      };
      Axios.get("liquidacion")
        .then((r) => {
          this.liquidaciones = r.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>